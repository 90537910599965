export default {
  primary: {
    backgroundColor: 'primary',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: '1px',
    borderLeft: 'none',
    borderRight: 'none',
    borderRadius: '5px',
    ':hover': {
      backgroundColor: 'secondary',
      borderColor: 'secondary',
    },
  },
  secondary: {
    backgroundColor: 'transparent',
    color: 'primary',
    border: 'solid 2px',
    borderColor: 'primary',
    ':hover': {
      backgroundColor: 'primary',
      color: 'white',
    },
  },
};
