export default {
  shoutSectionV2: {
    container: {
      width: '100%',
      flexDirection: ['column', 'row'],
      fontSize: [4, 4, 5, 6],
      justifyContent: 'center',
    },
    content: {
      order: '2',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: ['flex-start', 'flex-start', 'center'],
      padding: 4,
      color: 'text',
      width: ['100%', '50%'],
      flexGrow: '1',
    },
    title: {
      fontSize: '2em',
      margin: '0 auto',
      maxWidth: '500px',
      textAlign: ['left', 'left', 'center'],
      width: '100%',
    },
    text: {
      textAlign: ['left', 'left', 'center'],
      maxWidth: '500px',
      margin: '0 auto',
      fontSize: '1em',
      width: '100%',
    },
    date: {
      textAlign: ['left', 'left', 'center'],
      fontSize: '0.75em',
      margin: '1rem auto',
      maxWidth: '500px',
      width: '100%',
    },
    imageContainer: {
      position: 'relative',
      width: ['100%', '50%'],
      flexGrow: '1',
      img: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    imageFill: {
      paddingBottom: '100%',
    },
    shoutCTAS: {
      flexWrap: 'wrap',
      marginBottom: '0.5rem',
      transition: 'all ease-in-out 0.3s',
    },
    shoutCTA: {
      variant: 'buttons.primary',
      margin: '0.5rem 1rem 0.5rem 0rem',
    },
  },
}
