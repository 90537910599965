export default [
  '0.25rem',
  '0.5rem',
  '0.75rem',
  '1rem',
  '1.25rem',
  '1.5rem',
  '1.75rem',
  '2rem',
  '2.5rem',
  '3rem',
  '3.5rem',
  '4rem',
  '4.5rem',
  '5rem',
  '5.5rem',
  '6rem',
  '6.5rem',
  '7rem',
]
