import buttons from './buttons';

export default {
  // ? ===   Layout  ====

  navigation: {
    // ! Shadowed
  },

  footer: {
    borderTop: '2px solid',
    borderColor: 'secondary',
    backgroundColor: 'white',
    color: 'text',
    '.multiButtonContainer': {
      flexDirection: ['', '', '', 'column'],
    },

    '.sectionHeading': {
      color: 'secondary',
      textTransform: 'uppercase',
      fontSize: '1.5rem',
    },
    '.logo': {
      borderBottom: '1px solid',
      borderColor: 'secondary',
      paddingBottom: '1rem',
      marginBottom: '1rem',
      filter: 'brightness(1) invert(1)',
    },
    '.quote': {
      fontFamily: 'display',
      color: 'secondary',
      fontWeight: 'bold',
    },
    '.gonationLogo': {
      filter: 'unset !important',
    },
    '.socialContainer svg path': {
      fill: 'black',
    },

    '.ctaButton': {
      variant: 'buttons.primary',
      marginTop: '1rem',
    },
  },

  ctaWidget: {},

  // ? ===========================
  // ? =====   reuseables   ======
  // ? ===========================

  title: {
    fontFamily: 'heading',
    fontSize: ['2rem', '2.5rem', '3rem', '3.5rem'],
    textTransform: 'uppercase',
    fontWeight: 'normal',
  },
  subtitle: {
    fontSize: ['1rem', '1.5rem', '2rem', '2.5rem'],
    fontFamily: 'body',
    color: 'primary',
    fontWeight: 'normal',
    textTransform: 'uppercase',
  },
  text: {
    fontSize: ['1.1rem'],
    color: 'text',
    opacity: '0.8',
    p: {
      fontSize: ['1.1rem'],
      color: 'text',
      lineHeight: '1.8',
    },
  },

  sideBySide1: {
    '.content': {
      alignItems: 'center',
      textAlign: 'center',
      padding: ['2rem 1rem', '', '3rem', '4rem', ' 5rem'],
    },
    '.title': {
      variant: 'customVariants.title',
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
    },
    '.text': {
      variant: 'customVariants.text',
    },
    '.ctaButton': {
      variant: 'buttons.primary',
    },
  },

  sideBySide1: {
    '.content': {
      alignItems: 'center',
      textAlign: 'center',
      padding: ['2rem 1rem', '', '3rem', '4rem', ' 5rem'],
    },
    '.lazyload-wrapper': {},
    '.title': {
      variant: 'customVariants.title',
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
    },
    '.text': {
      variant: 'customVariants.text',
    },
    '.ctaButton': {
      variant: 'buttons.primary',
    },
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.content': {
      alignItems: 'center',
      textAlign: 'center',
      padding: ['2rem 1rem', '', '3rem', '4rem', ' 5rem'],
      order: '2',
    },
    '.lazyload-wrapper': {},
  },

  // ? ===   Homepage  ====

  homepageHero: {
    height: ['100vh', '', '', 'calc(100vh - 105px)'],
    '.hero_content_container': {
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 auto',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      padding: '1rem',
      maxWidth: '767px',
      width: '90%',
    },

    '.title': {
      fontSize: ['2.5rem', '3rem', '5rem', '5.5rem'],
      textTransform: 'uppercase',
      textShadow: '3px 1px #000000',
      color: 'white',
    },

    '.subtitle': {
      color: 'lightgrey',
    },

    '.ctaButton': {
      variant: 'buttons.primary',
    },

    '.slick-initialized .slick-slide > div': {
      height: ['100vh', '', '', 'calc(100vh - 105px)'],
    },

    '.slick-slide img': {
      height: ['100vh', '', '', 'calc(100vh - 105px)'],
      filter: 'brightness(0.5)',
    },

    '.slick-next': {
      bottom: '1rem',
      left: '3rem',
      top: 'unset',
    },
    '.slick-prev': {
      bottom: '1rem',
      left: '1.5rem',
      top: 'unset',
    },
  },
  homepageHeroShout: {
    display: 'none',
  },

  homepageTagline: {
    background: 'unset',
    backgroundColor: 'dark',
    padding: '8rem 1rem',
    color: 'white',
    '.section': {
      maxWidth: 'unset',
    },
    '.title': {
      fontSize: ['2rem', '3rem', '4rem', '5rem'],
      textTransform: 'uppercase',
    },
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
    '.content': {
      alignItems: 'center',
      textAlign: 'center',
      padding: ['2rem 1rem', '', '3rem', '4rem', ' 5rem'],
      width: ['100%', '', '50%', '60%', '70%'],
    },
    '.lazyload-wrapper': {
      width: ['100%', '', '50%', '40%', '30%'],
    },
  },

  homepageShout: {
    overflowX: 'hidden',
    flexDirection: ['column', 'column', 'row'],
    margin: '0rem',
    padding: '0rem',
    '.imageContainer': {
      width: ['100%', '100%', '40%', '25%'],
    },
    '.image': {
      padding: ['0rem', '', '1rem', '2rem'],
      objectFit: 'contain',
    },

    '.text': { lineHeight: '1.5', fontSize: '1.4rem' },
    '.title': {
      width: ['3rem', '', '', '4rem'],
      textTransform: 'uppercase',
    },
    '.content': {
      backgroundColor: ['', '', '#ece2cd'],
      margin: '0rem',
      padding: '5rem 2rem',
      width: ['', '100%', '60%'],
    },
    '.shoutCTA': {
      variant: 'buttons.primary',
    },
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide1',
  },
  homepagePricing: {
    variant: 'customVariants.sideBySide2',
  },

  homepageTestimonials: {
    padding: ['2rem 1rem', '', '6rem 1rem'],
    maxWidth: 'unset',
    backgroundColor: 'dark',
    '.menuSectionTitle': {
      variant: 'customVariants.title',
      color: 'white',
      border: 'none',
      textAlign: 'center',
      fontWeight: 'normal',
      margin: '0 auto 1rem',
    },

    '.menuSectionDescription': {
      variant: 'customVariants.subtitle',
      fontSize: '1rem',
    },

    '.menuItemNameContainer': {
      variant: 'customVariants.subtitle',
      order: '4',
      margin: '1rem auto 0rem',
      textAlign: 'center',
    },

    '.menuItemName': {
      fontSize: ['1rem', '1rem', '1rem', '1rem'],
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '::after': {
        content: "'★★★★★'",
        margin: '1rem',
        fontSize: '2rem',
      },
    },

    '.menuItemDescription': {
      variant: 'customVariants.text',
      color: 'white',
      fontSize: ['1.1rem', '', '1.25rem'],
      textAlign: 'center',
      lineHeight: '1.75',
      width: '100%',
      padding: ['0rem', '', '0rem 1rem'],
      margin: '1rem auto 2rem',
    },

    '.slick-slider': {
      maxWidth: '767px',
      margin: '0 auto',
    },
    '.slick-slider .slick-arrow': {
      width: '40px',
      height: '40px',
      padding: '0.2rem',
      border: 'solid 1px white',
      color: 'white',
    },
    '.slick-dots li button:before': {
      fontSize: '1.1rem',
      color: 'white',
    },
    '.slick-dots li.slick-active button:before': {
      color: 'primary',
    },
  },

  // ? =======  About page  ========

  aboutPageAboutSection: {
    '.title': {
      color: 'secondary',
    },
  },

  // ? =======  Menu page  ========

  menu: {
    // background: '#ddd1bf',
    maxWidth: 'unset',
    color: 'black',
    '.dropdownContainer': {
      display: 'none',
    },
    // '.menu > .menuContainer > .menuSectionTitle': {
    //   display: 'none',
    // },

    // *== Cells ==

    '.menuCell': {
      display: 'flex',
      borderRadius: '0px',
    },
    '.cellImage': {
      borderRadius: '0px',
    },

    '.cellName': {
      color: 'white',
      textAlign: 'center',
      width: '100%',
      margin: '0rem',
      padding: '1rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    // *==  All in ==

    '.allInContainerWrapper': {
      borderLeft: ['solid 10px', '', 'solid 30px'],
      borderColor: ['primary', '', 'primary'],
      paddingLeft: ['1rem', '2rem'],
    },
    '.allInContainer': {
      maxWidth: '1200px',
      margin: '0 auto',
    },
    '.menuContainer': {
      paddingBottom: '0rem',
    },

    // *==  menu section ==

    '.menuSectionTitle': {
      textShadow: '2px 3px grey',
      fontSize: ['2rem', '2.5rem', '3rem', '3.5rem'],
    },
    '.menuSectionDescription': {
      maxWidth: '767px',
      margin: '2rem auto',
    },

    // *==  menu Item ==

    '.menuItemContentContainer': {
      margin: '0rem',
    },
    '.menuItemName': {
      fontFamily: 'Lato, sans-serif',
      fontWeight: '700',
      marginBottom: '0.25rem',
      textTransform: 'uppercase',
    },
    '.menuItemDescription': {
      fontFamily: 'Lato, sans-serif',
      fontStyle: 'italic',
      fontWeight: '300',
      textTransform: 'capitalize',
    },

    '.menuItemPrice': {
      color: 'black',
    },
  },

  // ? ====  Events and Offers  ===

  eventsSection: {
    '.heading': {
      display: 'none',
    },
    '.textContent .title': {
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '', '', '2.5rem'],
      color: 'secondary',
      textAlign: 'center',
      maxWidth: '1000px',
    },
    '.modal': {
      backgroundColor: 'secondary',
      '.eventDate': { color: 'white' },
      '.modalEventTime': { color: 'white' },
    },
    '.logo': {
      filter: 'brightness(1) invert(1)',
    },
  },

  offersSection: {
    '.recurring-header': {
      display: 'none',
    },
    '.textContent .title': {
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '', '', '2.5rem'],
      color: 'secondary',
      textAlign: 'center',
      maxWidth: '1000px',
    },
    '.modal': {
      backgroundColor: 'secondary',
      '.eventDate': { color: 'white' },
      '.modalEventTime': { color: 'white' },
      '.eventDays': { color: 'white' },
    },
  },

  // ? ====  Gallery Page  ===

  gallery: {
    '.albumImage': {
      borderTop: '1px solid white',
      borderBottom: '1px solid white',
      variant: 'buttons.primary',
    },
    '.albumTitle': {
      variant: 'buttons.primary',
      padding: '0.5rem',
    },
  },

  // ? ====  Contact Page  ===

  contactForm: {
    padding: '3rem 1rem',
    order: '4',
    '.title': {
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '', '', '2.5rem'],
      color: 'secondary',
    },
    '.subtitle': {
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '2rem',
    },
  },

  locationMap: {
    order: '3',
    '.title': {
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '', '', '2.5rem'],
      color: 'secondary',
    },
    '.contactDetails-container, .hoursContainer ': {
      opacity: '0.9',
      '::after': {
        content:
          "'Our season run April through October. Please contact us to book your event!'",
        marginTop: '1.75rem',
        maxWidth: '380px',
        color: 'primary',
        fontSize: '1.1rem',
      },
    },
    '.hours-section': {
      display: 'none',
    },
  },

  // ? ====  safety Page  ===

  safety: {
    '.menuSectionTitle': {
      display: 'none',
    },
    '.menuItemName': {
      fontSize: ['1rem', '1.1rem', '', '1.3rem'],
      fontWeight: 'bolder',
      margin: '0px',
      paddingRight: '8px',
      fontFamily: 'heading',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      '::before': {
        content: "'➤'",
        marginRight: '1rem',
        color: 'secondary',
      },
      textAlign: 'left',
      lineHeight: '1.25',
      color: 'primary',
    },
  },
};
