export default {
  heading: {
    fontFamily: 'heading',
    fontWeight: 'heading',
    lineHeight: 'heading',
    textTransform: 'capitalize',
  },
  text: {
    lineHeight: 'body',
    fontFamily: 'body',
    fontWeight: 'body',
    fontSize: '1rem',
    color: 'text',
  },
  navLink: {
    fontFamily: 'heading',
    fontWeight: 'heading',
    lineHeight: 'heading',
  },
  link: {
    textDecoration: 'none',
    color: 'unset',
  },
}
