const variantName = 'galleryV5'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      padding: '2rem 0.5rem',
      width: '100%',
      '.lazyload-wrapper': {
        width: '100%',
      },
    },
    wrapper: {
      width: '100%',
    },

    // ?============================
    // ?====  Albums Containers ====
    // ?============================

    albumsContainer: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      padding: '1rem 0rem',
      marginBottom: '2rem',
      borderTop: '1px solid',
      borderBottom: '1px solid',
      borderColor: 'primary',
    },

    albumsCTA: {
      margin: '0.5rem',
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: ['1', '', 'unset'],
      textAlign: 'center',
    },
    albumsCTAActive: {
      variant: `${variantName}.albumsCTA`,
      opacity: '0.7',
      textDecoration: 'underline',
    },

    albumTitle: {
      variant: 'buttons.primary',
      width: '100%',
      fontSize: ['1rem', '1rem', '1.5rem'],
    },

    // ?============================
    // ?=====  Album Container =====
    // ?============================
    albumContainer: {
      display: 'flex',
    },

    albumName: {
      display: 'flex',
      width: 'auto',
      writingMode: 'vertical-lr', //* writting mode changes the width property in unexpected ways
      marginRight: ['1.5rem', '', '2rem', '3rem'], //* this has to match the fontsize to get the Right height
      paddingTop: '1rem',
      opacity: '0.6',
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '', '2rem', '3rem'], //* this has to match the MarginRight to get the Right height
    },

    albumPhotos: {
      flexWrap: 'wrap',
      alignItems: 'stretch',
      margin: '0rem auto 1rem',
      justifyContent: 'center',
      gridGap: 4,
      gridTemplateColumns: 'repeat( auto-fill, minmax( 250px, 1fr ) )',
      gridAutoRows: '100px',
      padding: '0rem 0.5rem',
      display: 'grid !important',
    },
    albumImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      padding: 1,
      gridRowEnd: 'span 2',
      ':nth-child(4n+1)': {
        gridRowEnd: 'span 3',
      },
    },
  },
}
